<template>
	<div class="notice_popup view">
		<div class="close">
			<img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
		</div>
		<div class="view-tit">
			<span v-if="forwardData.div == 'dco'">수요기업</span><span v-else>공급기업</span> 정보 / <span>{{corpInfo.corpNm}}<div class="service_type"><SelectComp type="text" list="N:무료,Y:유료" v-model="corpInfo.contractYn"/> 서비스</div></span>
		</div>
		<div class="view">
			<div class="list">
				<div class="label">회사명</div><div class="contents">{{corpInfo.corpNm}}</div><div class="mf_btn" @click="modify()" >정보수정 &#9654;</div>
			</div>
			<div class="list">
				<div class="label">사업자등록번호</div><div class="contents">{{corpInfo.bizRegNo | bizNo}}</div>
			</div>
			<div class="list">
				<div class="label">산업분야</div><div class="contents"><SelectComp type="text" cdId="PRO105" v-model="corpInfo.indtypeClassCd" /></div>
			</div>
			<div class="list">
				<div class="label">직원수</div><div class="contents">{{corpInfo.empCnt | number}}<span v-if="corpInfo.empCnt">명</span></div>
			</div>
			<div class="list">
				<div class="label">주소</div><div class="contents">{{corpInfo.companyZipNo}} {{corpInfo.companyAddr}} {{corpInfo.companyAddrDtl}}</div>
			</div>
			<div class="list">
				<div class="label">홈페이지</div><div class="contents">{{corpInfo.homepageAddr}}</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props:['param'],
	data() {
		return {
			corpInfo : {},

			forwardData : this.param,
		}
	},

	beforeMount() {
		this.getDemandCorpInfo();
	},

	methods : {
		getDemandCorpInfo() {
			this.$.httpPost('/api/mem/adm/dco/getDemandCorpInfo', this.forwardData)
				.then(res => {
					//console.log('getDemandCorpInfo RESULT', res.data.corpInfo);
					this.corpInfo = res.data.corpInfo;

					this.$emit('updated');
				}).catch(this.$.httpErrorCommon);
		},
		

		modify() {
			//console.log('corpSeq', this.corpInfo.corpSeq);
			//console.log('forwardData', this.forwardData);

			var div = this.forwardData.div

			if(div == 'dco') {
				this.$router.push({name:'MEM921M01', params:{corpSeq:this.corpInfo.corpSeq}});
				this.$emit('close');

			}else {
				this.$router.push({name:'MEM936M01', params:{corpSeq:this.corpInfo.corpSeq}});
				this.$emit('close');
			}

		}
	}
}
</script>